<template>
  <b-card>
    <!-- media -->
    <b-media no-body>
      <b-media-aside>
        <b-link>
          <b-img
            ref="previewEl"
            rounded
            src="@/assets/images/profile/user-uploads/player.jpg"
            height="80"
          />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>
    </b-media>
    <!--/ media -->
    <validation-observer ref="validationRules">
      <!-- form -->
      <b-form
        class="mt-2"
        @submit.prevent="onSubmit"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col sm="6">
            <b-form-group
              :label="$t('FIRST_NAME')"
              label-for="id-firstname"
            >
              <b-form-input
                v-model="user.firstName"
                maxlength="45"
                :placeholder="$t('FIRST_NAME')"
                readonly
                name="id-firstname"
              />

            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              :label="$t('LAST_NAME')"
              label-for="id-lastname"
            >
              <b-form-input
                v-model="user.lastName"
                maxlength="45"
                name="id-lastname"
                readonly
                :placeholder="$t('LAST_NAME')"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              :label="$t('EMAIL_ADDRESS')"
              label-for="id-e-mail"
            >
              <b-form-input
                v-model="user.email"
                maxlength="60"
                name="id-email"
                readonly
                :placeholder="$t('EMAIL_ADDRESS')"
              />

            </b-form-group>
          </b-col>
          <!-- phone -->
          <b-col md="6">
            <b-form-group
              label-for="id-phone"
              :label="$t('PHONE_NUMBER')"
            >
              <b-form-input
                id="id-phone"
                v-model="user.phoneNumber"
                maxlength="45"
                readonly
                placeholder="08-121 316 16"
              />
            </b-form-group>
          </b-col>
          <!-- new password -->
          <b-col md="6">
            <b-form-group
              label-for="account-new-password"
              :label="$t('NEW_PASSWORD')"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('NEW_PASSWORD')"
                vid="Password"
                rules="required|password"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-new-password"
                    v-model="user.password"
                    :type="passwordFieldTypeNew"
                    name="new-password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconNew"
                      class="cursor-pointer"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ new password -->

          <!-- retype password -->
          <b-col md="6">
            <b-form-group
              label-for="account-retype-new-password"
              :label="$t('RETYPE_NEW_PASSWORD')"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('RETYPE_NEW_PASSWORD')"
                rules="required|confirmed:Password"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-retype-new-password"
                    v-model="retypePassword"
                    :type="passwordFieldTypeRetype"
                    name="retype-password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconRetype"
                      class="cursor-pointer"
                      @click="togglePasswordRetype"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ retype password -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              @click="onSubmit()"
            >
              {{ $t('BUTTON.SAVE_CHANGES') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              type="reset"
              class="mt-2"
            >
              {{ $t('BUTTON.RESET') }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput,
  BRow, BCol,
  BCard, BInputGroup, BInputGroupAppend,
  BMedia, BMediaAside, BLink, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// eslint-disable-next-line import/no-extraneous-dependencies
import { required } from '@validations'
import { mapActions } from 'vuex'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
// eslint-disable-next-line import/extensions, import/no-unresolved
import mixinAlert from '@/constants/mixinAlert'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BMedia,
    BMediaAside,
    BLink,
    BImg,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      user: {
        id: '',
        firstName: '',
        lastName: '',
        nick: '',
        companyId: '',
        email: '',
        phoneNumber: '',
        userRoleId: 0,
        aboutMe: '',
        isSendDailyEmail: false,
        password: '',
        language: '',
      },
      retypePassword: '',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      required,
    }
  },
  setup() {
    const {
      successMessage, errorMessage, showErrorMessage,
    } = mixinAlert()

    return {
      successMessage, errorMessage, showErrorMessage,
    }
  },
  computed: {
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  async created() {
    if (router.currentRoute.params.id) {
      await this.fetchUser(router.currentRoute.params.id)
    }
  },
  methods: {
    ...mapActions('user', ['resetPassword', 'fetchUserByID']),
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    onSubmit() {
      this.$refs.validationRules.validate().then(success => {
        if (success) {
          if (this.user.id) {
            const payload = {
              Email: this.user.email,
              newPassword: this.user.password,
              confirmPassword: this.retypePassword,
            }
            this.resetPassword(payload).then(response => {
              if (response) {
                this.successMessage(this.$i18n.t('MESSAGE.PASSWORD_UPDATED'))
                this.$router.push({ name: 'user-list' })
              }
            }).catch(error => {
              if (error.response && error.response.status === 400) {
                this.errorMessage(error.response.data.message)
              } else {
                this.showErrorMessage()
              }
            })
          }
        }
      })
    },
    async fetchUser(id) {
      try {
        await this.fetchUserByID(id).then(response => {
          if (response) {
            const { data } = response.data
            this.user = data
          }
        })
      } catch (error) {
        this.showErrorMessage()
      }
    },
  },
}
</script>
